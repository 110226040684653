'use client';

import type { ReactNode } from 'react';
import { UiContext } from '@/contexts/UiContext';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useCallback, useLayoutEffect, useState } from 'react';
import { ExternalToast, toast, Toaster } from 'sonner';
import { IModalContent, IToastContent, IWindowContent } from '@/types/api';
import { logInfo, logWarning } from '@/utils/Logger';
import { AlertDialog, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogContent, AlertDialogFooter, AlertDialogAction, AlertDialogCancel } from '@/components/ui/alert-dialog';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useTranslations } from 'next-intl';
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle } from '@/components/ui/drawer';
import { Sheet, SheetClose, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { Button } from '@/components/ui/button';
import React from 'react';

export const UiProvider = ({ children }: { children: ReactNode }) => {
    const trans = useTranslations('Common');
    const isDesktop = useMediaQuery(768);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [drawerContent, setDrawerContent] = useState<ReactNode | null>(null);
    const [isSheetOpen, setIsSheetOpen] = useState<boolean>(false);
    const [sheetContent, setSheetContent] = useState<ReactNode | null>(null);

    const showModal = useCallback(async (content: ReactNode) => {
        setModalContent(content);
        setIsModalOpen(true);
    }, []);

    const hideModal = useCallback(() => {
        setIsModalOpen(false);
        setModalContent(null);
    }, []);

    const showDialog = useCallback((content: ReactNode) => {
        setDialogContent(content);
        setIsDialogOpen(true);
    }, []);

    const hideDialog = useCallback(() => {
        setIsDialogOpen(false);
        setDialogContent(null);
    }, []);

    const showDrawer = useCallback((content: ReactNode) => {
        setDrawerContent(content);
        setIsDrawerOpen(true);
    }, []);

    const hideDrawer = useCallback(() => {
        setIsDrawerOpen(false);
        setDrawerContent(null);
    }, []);

    const showSheet = useCallback((content: ReactNode) => {
        setSheetContent(content);
        setIsSheetOpen(true);
    }, []);

    const hideSheet = useCallback(() => {
        setIsSheetOpen(false);
        setSheetContent(null);
    }, []);

    const showWindow = useCallback((content: ReactNode) => {
        if (isDesktop) {
            showDialog(content);
        } else {
            showDrawer(content);
        }
    }, [isDesktop, showDialog, showDrawer]);

    const hideWindow = useCallback(() => {
        hideDialog();
        hideDrawer();
    }, [hideDialog, hideDrawer]);

    const showToast = useCallback((content: IToastContent) => {
        switch (content.type) {
            case 'error':
                toast.error(content.title, content as ExternalToast);
                break;
            case 'warning':
                toast.warning(content.title, content as ExternalToast);
                break;
            case 'info':
                toast.info(content.title, content as ExternalToast);
                break;
            case 'message':
                toast.message(content.title, content as ExternalToast);
                break;
            case 'success':
                toast.success(content.title, content as ExternalToast);
                break;
            default:
                logWarning(`UiProvider: unsupported toast content type: ${content.type}.`);
                toast.message(content.title, content as ExternalToast);
                break;
        }
    }, []);

    const hideToast = useCallback((toastId?: string) => {
        toast.dismiss(toastId);
    }, []);

    const showLoadingToast = useCallback((toastId: string) => {
        toast.loading(trans('loading'), {
            id: toastId,
            description: trans('please_wait'),
        });
    }, [trans]);

    const showDefaultErrorToast = useCallback((toastId: string, description?: string) => {
        showToast({
            id: toastId,
            title: trans('error_occurred'),
            description: description ?? trans('something_went_wrong'),
            type: 'error',
            duration: 1800,
        })
    }, [trans]);

    const showDefaultSuccessToast = useCallback((toastId: string, description?: string) => {
        showToast({
            id: toastId,
            title: 'Ok!',
            description: description ?? trans('operation_successful'),
            type: 'success',
            duration: 1200
        });
    }, [trans, showToast]);

    const handleModalChange = (data: any) => {
        setIsModalOpen(false);
        setModalContent(null);
        logInfo("HANDLE MODAL CHANGE TEST!", { data });
    };

    const show = useCallback((content: ReactNode, type: 'modal' | 'dialog' | 'drawer' | 'sheet') => {
        switch (type) {
            case 'modal':
                showModal(content);
                break;
            case 'dialog':
                showDialog(content);
                break;
            case 'drawer':
                showDrawer(content);
                break;
            case 'sheet':
                showSheet(content);
                break;
            default:
                logWarning(`Unsupported modal type: ${type}. Use only 'modal', 'dialog', 'drawer', and 'sheet'`);
                break;
        }
    }, [showModal, showDialog, showDrawer, showSheet]);

    return (
        // eslint-disable-next-line react/no-unstable-context-value
        <UiContext.Provider value={{ show, showModal, hideModal, showDialog, hideDialog, showDrawer, hideDrawer, showSheet, hideSheet, showWindow, hideWindow, showToast, hideToast, showLoadingToast, showDefaultErrorToast, showDefaultSuccessToast }}>
            <Toaster
                position={isDesktop ? 'top-right' : 'top-center'}
                richColors
                pauseWhenPageIsHidden
            />
            {modalContent && (
                <AlertDialog open={isModalOpen} onOpenChange={handleModalChange}>
                    {modalContent}
                </AlertDialog>
            )}
            {dialogContent && (
                <Dialog open={isDialogOpen} onOpenChange={hideDialog}>
                    {dialogContent}
                </Dialog>
            )}
            {children}
            {drawerContent && (
                <Drawer open={isDrawerOpen} onOpenChange={hideDrawer} onDrag={hideDrawer}>
                    {drawerContent}
                </Drawer>
            )}
            {sheetContent && (
                <Sheet open={isSheetOpen} onOpenChange={hideSheet}>
                    {sheetContent}
                </Sheet>
            )}
        </UiContext.Provider>
    );
};
