import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

type DashboardState = {
    isSaving: boolean;
    isSaved: boolean | null;
};

const initialState: DashboardState = {
    isSaving: false,
    isSaved: null,
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setIsSaved: (state, action: PayloadAction<boolean | null>) => {
            state.isSaved = action.payload;
        },
    },
});

export const { setIsSaving, setIsSaved } = dashboardSlice.actions;

export default dashboardSlice.reducer;
