import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/var/www/front/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/front/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/front/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/front/src/app/global.css");
;
import(/* webpackMode: "eager" */ "/var/www/front/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Big_Shoulders_Text\",\"arguments\":[{\"subsets\":[\"latin\",\"latin-ext\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"]}],\"variableName\":\"bigShoulders\"}");
;
import(/* webpackMode: "eager" */ "/var/www/front/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Roboto_Mono\",\"arguments\":[{\"subsets\":[\"latin\",\"latin-ext\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\",\"fallback\":[\"system-ui\"],\"variable\":\"--font-mono\"}],\"variableName\":\"robotoMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderContainer"] */ "/var/www/front/src/components/custom/HeaderContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/var/www/front/src/providers/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReduxProvider"] */ "/var/www/front/src/providers/ReduxProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UiProvider"] */ "/var/www/front/src/providers/UiProvider.tsx");
