'use client';

import { useAuth } from "@/contexts/AuthContext";
import TextLogo from "./TextLogo";
import { MenuButton } from "./buttons/MenuButton";
import { SignUpButton } from "./buttons/SignUpButton";
import LoginButton from "./buttons/LoginButton";
import UserButton from "./buttons/UserButton";
import Link from "next/link";
import { usePathname } from "next/navigation";

export function HeaderContainer() {
    const { user } = useAuth();
    const pathname = usePathname();

    return (
        <>
            <div className="flex flex-1 flex-row items-center">
                {pathname !== '/gavynglass' && (
                    user ? (
                        <UserButton user={user} />
                    ) : (
                        <LoginButton />
                    )
                )}
            </div>
            <div className="flex flex-1 items-center justify-center text-brand text-4xl">
                <Link href="/">hus.sh</Link>
            </div>
            <div className="flex flex-1 flex-row space-x-2 sitems-center justify-end">
                {pathname !== '/gavynglass' && (
                    user ? (
                        <MenuButton />
                    ) : (
                        <SignUpButton />
                    )
                )}
            </div>
        </>
    );
};