'use client';

import {
    Cloud,
    CreditCard,
    Keyboard,
    LifeBuoy,
    LoaderIcon,
    LogOut,
    LogOutIcon,
    Mail,
    MenuIcon,
    MessageCircleQuestionIcon,
    MessageSquare,
    Plus,
    PlusCircle,
    Router,
    Settings,
    SettingsIcon,
    User,
    UserIcon,
    UserPlus,
    Users,
} from 'lucide-react';

import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuPortal, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import Link from 'next/link';
import { useAuth } from '@/contexts/AuthContext';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { useTranslations } from 'next-intl';
import LoginButton from './LoginButton';
import { IUser } from '@/types/api';
import { useCallback, useState } from 'react';
import { toast } from 'sonner';
import { logError } from '@/utils/Logger';
import { AlertDialogDescription, AlertDialogTitle, AlertDialogContent, AlertDialogHeader, AlertDialogFooter } from '@/components/ui/alert-dialog';
import { useUi } from '@/contexts/UiContext';
import { AlertDialogAction, AlertDialogCancel } from '@radix-ui/react-alert-dialog';

export function MenuButton() {
    const trans = useTranslations('Common');
    const { user, logout } = useAuth();
    const { showModal } = useUi();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const showErrorModal = useCallback(() => {
        showModal(
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Error Occurred.</AlertDialogTitle>
                    <AlertDialogDescription>Something went wrong. Please contact us for details.</AlertDialogDescription>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Close</AlertDialogCancel>
                        <AlertDialogAction>Ok</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogHeader>
            </AlertDialogContent>
        )
    }, [showModal]);

    const handleLogout = useCallback(async () => {
        setIsLoading(true);
        try {
            const isOk = await logout();
            if (isOk) {
                toast.info('Bye-bye');
            } else {
                throw new Error('API exception occurred.');
            }
        } catch (error: any) {
            showErrorModal();
            logError(error.message);
        } finally {
            setIsLoading(false);
        }
    }, [logout, showErrorModal]);

    if (!user) return null;

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button size="icon" variant="outline" className="shrink-0">
                    <MenuIcon />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>{`Welcome, ${user.username}!`}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <Link href={`/dashboard/${user.id}`}>
                        <DropdownMenuItem>
                            <SettingsIcon />
                            <span>Dashboard</span>
                            <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
                        </DropdownMenuItem>
                    </Link>
                    <DropdownMenuItem>
                        <MessageCircleQuestionIcon />
                        <span>Light</span>
                        <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
                    </DropdownMenuItem>
                    <Link href='/contact-us'>
                        <DropdownMenuItem>
                            <MessageCircleQuestionIcon />
                            <span>Contact Us</span>
                            <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
                        </DropdownMenuItem>
                    </Link>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <DropdownMenuItem onClick={handleLogout}>
                        {isLoading ? (
                            <LoaderIcon className="animate-spin" />
                        ) : (
                            <LogOutIcon />
                        )}
                        <span>{trans('logout')}</span>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    )
    //   <ModeToggle />
    // return (
    //     <DropdownMenu>
    //         <DropdownMenuTrigger asChild>
    //             <Button size="icon" variant="outline" className="shrink-0"><MenuIcon /></Button>
    //         </DropdownMenuTrigger>
    //         <DropdownMenuContent className="w-56">
    //             <DropdownMenuLabel>My Account</DropdownMenuLabel>
    //             <DropdownMenuSeparator />
    //             <DropdownMenuGroup>
    //                 <DropdownMenuItem>
    //                     <UserIcon />
    //                     <span>Profile</span>
    //                     <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
    //                 </DropdownMenuItem>
    //                 <DropdownMenuItem>
    //                     <CreditCard />
    //                     <span>Billing</span>
    //                     <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
    //                 </DropdownMenuItem>
    //                 <DropdownMenuItem>
    //                     <Settings />
    //                     <span>Settings</span>
    //                     <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
    //                 </DropdownMenuItem>
    //                 <DropdownMenuItem>
    //                     <Keyboard />
    //                     <span>Keyboard shortcuts</span>
    //                     <DropdownMenuShortcut>⌘K</DropdownMenuShortcut>
    //                 </DropdownMenuItem>
    //             </DropdownMenuGroup>
    //             <DropdownMenuSeparator />
    //             <DropdownMenuGroup>
    //                 <DropdownMenuItem>
    //                     <Users />
    //                     <span>Team</span>
    //                 </DropdownMenuItem>
    //                 <DropdownMenuSub>
    //                     <DropdownMenuSubTrigger>
    //                         <UserPlus />
    //                         <span>Invite users</span>
    //                     </DropdownMenuSubTrigger>
    //                     <DropdownMenuPortal>
    //                         <DropdownMenuSubContent>
    //                             <DropdownMenuItem>
    //                                 <Mail />
    //                                 <span>Email</span>
    //                             </DropdownMenuItem>
    //                             <DropdownMenuItem>
    //                                 <MessageSquare />
    //                                 <span>Message</span>
    //                             </DropdownMenuItem>
    //                             <DropdownMenuSeparator />
    //                             <DropdownMenuItem>
    //                                 <PlusCircle />
    //                                 <span>More...</span>
    //                             </DropdownMenuItem>
    //                         </DropdownMenuSubContent>
    //                     </DropdownMenuPortal>
    //                 </DropdownMenuSub>
    //                 <DropdownMenuItem>
    //                     <Plus />
    //                     <span>New Team</span>
    //                     <DropdownMenuShortcut>⌘+T</DropdownMenuShortcut>
    //                 </DropdownMenuItem>
    //             </DropdownMenuGroup>
    //             <DropdownMenuSeparator />
    //             <DropdownMenuItem>
    //                 <SiGithub size={16} />
    //                 <span>GitHub</span>
    //             </DropdownMenuItem>
    //             <DropdownMenuItem>
    //                 <LifeBuoy />
    //                 <span>Support</span>
    //             </DropdownMenuItem>
    //             <DropdownMenuItem disabled>
    //                 <Cloud />
    //                 <span>API</span>
    //             </DropdownMenuItem>
    //             <DropdownMenuSeparator />
    //             <DropdownMenuItem>
    //                 <Link href="/auth/logout">
    //                     <LogOutIcon />
    //                     <span>Log out</span>
    //                     <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
    //                 </Link>
    //             </DropdownMenuItem>
    //         </DropdownMenuContent>
    //     </DropdownMenu>
};
