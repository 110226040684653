'use client';

import { Button } from "@/components/ui/button";
import { useAuth } from "@/contexts/AuthContext";
import { useUi } from "@/contexts/UiContext";
import { useTranslations } from "next-intl";
import { useCallback, useState } from "react";

const LoginButton: React.FC = () => {
    const trans = useTranslations('Common');
    const { login } = useAuth();
    const { showDefaultErrorToast } = useUi();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleLogin = useCallback(async () => {
        setIsLoading(true);
        try {
            await login();
        } catch (error: any) {
            showDefaultErrorToast('login');
        } finally {
            setIsLoading(false);
        }
    }, [login, showDefaultErrorToast]);

    return (
        <>
            <Button
                variant="ghost"
                size="lg"
                disabled={isLoading}
                className="text-foreground p-[1em] rounded-lg"
                onClick={handleLogin}
            >
                {trans('login')}
            </Button>
        </>
    )
};

export default LoginButton;
