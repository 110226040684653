'use client';

import { Button } from "@/components/ui/button";
import { useAuth } from "@/contexts/AuthContext";
import { SiX } from "@icons-pack/react-simple-icons";
import { useTranslations } from "next-intl";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogClose, DialogTitle, DialogTrigger, } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { useCallback, useState } from "react";
import { AlertCircleIcon } from "lucide-react";
import { AlertDialogAction, AlertDialogCancel, AlertDialogFooter } from "@/components/ui/alert-dialog";
import { useUi } from "@/contexts/UiContext";

export function SignUpButton() {
    const { login } = useAuth();
    const { hideDialog } = useUi();
    const trans = useTranslations('Common');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const handleSignUp = useCallback(async () => {
        setIsLoading(true);
        try {
            await login();
        } catch (error: any) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [login]);

    return (
        <>
            <Dialog>
                <DialogTrigger asChild>
                    <Button
                        variant="ghost"
                        size="lg"
                        className="text-foreground p-[1em] rounded-lg"
                    >
                        {trans('sign_up')}
                    </Button>
                </DialogTrigger>
                <DialogContent className="max-w-[92%] rounded-lg">
                    <DialogHeader>
                        <DialogTitle>Sign-Up with X</DialogTitle>
                        <DialogDescription>
                            Make changes to your profile here. Click save when you're done.
                        </DialogDescription>
                    </DialogHeader>
                    <div className="p-4 flex items-center justify-center m-auto">
                        {isError ? (
                            <AlertCircleIcon size={96} className="text-red-500" />
                        ) : (
                            <SiX size={96} />
                        )}
                    </div>
                    {isError ?? <span>Something went wrong.</span>}
                    <DialogFooter>
                        <Button disabled={isLoading} onClick={handleSignUp} className="min-w-32 mt-2 sm:mt-0">{trans('continue')}</Button>
                        <DialogClose asChild><Button variant="secondary" disabled={isLoading} className="min-w-32">Cancel</Button></DialogClose>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
};