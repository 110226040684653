'use client';

import { IUser } from "@/types/api";
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { Button } from "@/components/ui/button";
import Link from "next/link";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useTranslations } from "next-intl";

type UserButtonProps = {
    user: IUser;
};

const UserButton: React.FC<UserButtonProps> = ({ user }) => {
    const trans = useTranslations('Common');

    return (
        <>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button variant="ghost" asChild>
                            <Link href={`/u/${user.username}`}>
                                <Avatar>
                                    <AvatarImage src={user.img} alt={`@${user.username}`} />
                                    <AvatarFallback>{user.initials}</AvatarFallback>
                                </Avatar>
                                <span>{user.username}</span>
                            </Link>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Watch Your Profile</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </>
    );
};

export default UserButton;