'use client';

import type { ReactNode } from 'react';
import { AuthContext } from '@/contexts/AuthContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import UnauthorizedException from '@/exceptions/UnauthorizedException';
import { sendGetRequest, sendPostRequest } from '@/lib/actions';
import { ApiEndpoints } from '@/utils/AppConfig';
import { useRouter } from 'next/navigation';
import { IUser } from '@/types/api';
import { logError } from '@/utils/Logger';

export const AuthProvider = ({ children, user: initialUser }: { children: ReactNode; user: IUser | null }) => {
    const router = useRouter();
    const [user, setUser] = useState<IUser | null>(initialUser);
    const [totalLinks, setTotalLinks] = useState<number>(0);
    const [totalFriends, setTotalFriends] = useState<number>(0);

    const toDashboard = useCallback(() => {
        if (user) router.push(`/dashboard/${user.id}/`);
    }, [user]);

    const login = useCallback(async (): Promise<void> => {
        try {
            const apiResponse = await sendPostRequest<{ url: string}>(ApiEndpoints.auth.LOGIN, {});
            if (apiResponse.success) {
                window.location.href = apiResponse.data.url;
            } else {
                throw new Error('Failed on login.');
            }
        } catch (error: any) {
            throw error;
        }
    }, []);

    const logout = useCallback(async (): Promise<boolean> => {
        if (user) {
            try {
                const apiResponse = await sendPostRequest(ApiEndpoints.auth.LOGOUT, {}, user?.id);
                if (apiResponse.success) {
                    setUser(null);
                    setTotalLinks(0);
                    setTotalFriends(0);
                    router.push('/');
                    return true;
                } else {
                    return false;
                }
            } catch (error: any) {
                logError(error.message);
                return false;
            }
        } else {
            return true;
        }
    }, []);

     const updateUserAvatar = useCallback((newAvatarUrl: string) => {
        if (!user) return;
        setUser({ ...user, img: newAvatarUrl });
    }, [user]);

    const updateUsername = useCallback((newUsername: string, newInitials: string) => {
        if (!user) return;
        setUser({ ...user, username: newUsername, initials: newInitials });
    }, []);

    const checkAuth = useCallback(async () => {
        if (!user) router.push('/');

        try {
            await sendGetRequest(ApiEndpoints.auth.CHECK);
        } catch (error: any) {
            if (error instanceof UnauthorizedException) logout();
        }
    }, [user]);

    useEffect(() => {
        if (user && typeof window !== 'undefined') {
            const linksCount = Number(localStorage.getItem('userLinksCount')) || 0;
            const friendsCount = Number(localStorage.getItem('userFriendsCount')) || 0;

            setTotalLinks(linksCount);
            setTotalFriends(friendsCount);
        }
    }, [user]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (user) checkAuth();
        }, 30000);

        return () => clearInterval(interval);
    }, [user, checkAuth]);

    const contextValue = useMemo(() => ({ user, totalLinks, totalFriends, toDashboard, login, logout, updateUserAvatar, updateUsername }), [user, updateUserAvatar, updateUsername]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};
